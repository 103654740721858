/* make the customizations */
@import "../node_modules/rfs/scss";

$theme-colors: (
        "primary": #C38D8D,
        "secondary": #9A4D57,
        "tertiary": #D89FAA
);

@font-face {
    font-family: 'Almeira';
    src: local('Almeira'), url(./fonts/Almeira-vm20L.ttf) format("truetype");
  }

@font-face {
font-family: 'Muli';
src: local('Muli'), url(./fonts/muli/Muli.ttf) format("truetype");
}

@font-face {
font-family: 'Sandbox';
    src: local('Sandbox'), url(./fonts/FontsFree-Net-SANDBOX_TTF.ttf) format("truetype");
    }

/* import bootstrap to set changes */
@import "../node_modules/bootstrap/scss/bootstrap";