@import "../custom.scss";


.message-1 {
    text-align: center;
    font-family: Muli;
    font-size: calc(0.6em + 1vw);
}


.base {
    padding-left: 100px;
    padding-right: 100px;
}

.about {
    text-align: center;
    font-family: Muli;
    font-size: calc(0.8em + 0.5vw);
}