.carousle-images {
    max-width: 80%;
    max-height: 40%;
    display: inline;
    text-align: center;
}

.carousle-item{
    background-color: white;
    text-align: center;
}